module.exports = {
    'common': {
        'currency': 'TL',
        'submit': 'Submit',
        'register_btn': 'Sign up',
        'login_btn': 'Login',
        'logout': 'Logout',
        'more': 'MORE',
        'submitSuccess': 'submit success',
        'copySuccess':'copy success',
        'saveImgTips':'long tap or screenshot',
        'maintenance': 'In the maintenance'
    },'navs':{
        'home': 'Home',
        'user': 'User',
        'profit': 'Profit',
        'college': 'College'
    },'register': {
        'title': 'Register',
        'otp': 'OTP',
        'label': {
            'referer': 'Referee',
            'account': 'Account',
            'password': 'Password',
            'verifyCode': 'OTP Code',
        },
        'placeholders': {
            'account': 'Your phone number',
            'otpCode': 'OTP code you received',
            'password': 'Your password',
            'referer': 'Your referer code'
        },
        'tips': {
            'iptError': 'The information is incorrect. Please check your input',
        },
        'already_registered': 'Already have an account?'
    },'login': {
        'title': 'Login',
        'label': {
            'account': 'Account',
            'password': 'Password',
        },
        'forget': 'Forget password?',
        'tips': {
            'iptError': 'Invalid input',
            'accountorpasswordwrong': 'account or password is wrong',
            'forget': 'Contact customer service to retrieve it',
        }
    },'wallet':{
        'amountLabel': 'Assets Balance',
        'endtime': 'END TIME',
        'noFundpassText': 'You have not set a fund password',
        'goSet': 'Go Set',
        'withdrawl':{
            'btn': 'Withdraw',
            'bankname': 'Bank Name',
            'bankno': 'Bank Number',
            'account': 'Bank Account',
            'amount': 'Quantity',
            'depositAmount': 'Amount',
            'password': 'Fund Password',
            'canuse': 'Balance',
            'max': 'Max',
            'forget': 'Forget?'
        },
        'labels':{
            'SerialNumber': 'Serial number',
            'orderId': 'Order ID',
        },
        'typeText': [
            "Buy Order","Share bonus","shopping bonus","Order back","Gift","Regist giving","Deposit Swap"
        ]
    },'tasks':{
        'join': 'JOIN',
        'andsoon': 'and so on {count} items....',
        'labels': {
            'amount':'Amount',
            'profit': 'Profit',
            'time': 'Date',
            'price': 'Price'
        },
        'time': '{time}days',
        'taskTypes':['OPEN','CLOSED'],
        'progress': ['WAITING','IN PROGRESS','ENDED'],
        'checkBtn': ['GOT!','CHECK'],
    },'recharge':{
        'amount': 'Amount',
        'recharge': 'Recharge',
        'status':["Pending","success",'canceled'],
        'tipEnterAmount': 'Please enter the amount you want to recharge'
    },'security':{
        'title': 'Security',
        'fundPass': 'Fund Password',
        'loginPass': 'Login Password',
        'oldPassword': 'Old Password',
        'password': 'New Password',
        'cpassword': 'Confirm',
        'cantbnull': 'Please enter both the original password and the new password',
        'diffrentPassword':'Password not consistent',
        'needFundpass': 'Please enter the fund password'
    },'profile': {
        'fixedAsset': 'Fixed Assets',
        'labels': {
            'available': 'Amount available',
            'freezz': 'Freeze of contract',
            'shoppingbonus': 'Shopping bonus',
            'teamcommission': 'Team commission',
            'todayprofit': 'Today profit',
            'withdrawled': 'Withdrawaled total'
        },
        'titles':{
            'invite': 'Invite',
            'accountSecurity': 'Security',
            'language': 'Language',
            'customService': 'Service',
            'aboutUs': 'About us',
            'team': 'My Teams',
            'logout': 'Logout'
        },
        'invite':{
            'saveUrl': 'Copy',
            'saveImg': 'Save',
        },
    },'withdrawl':{
        'title': 'Withdraw',
        'lackOfBalance': 'Insufficient balance',
        'cantbnull': 'Please enter the withdrawal amount and fund password',
        'forgetPassTips':'Reset fund password in user page',
        'placeholder': 'Amount you want to withdraw',
        'amount':'Amount',
        'status':["Pending","passed","Rejected"],
    },'team':{
        'totalInvited': 'Total invited',
        'searchPlaceholder': 'Search',
        
    },'share':{
        'title': 'Invite friends',
        'text': 'Join us',
    },'home':{
        'navs': {
            'recharge': 'Recharge',
            'withdrawl': 'Withdrawl',
            'diamond': 'Tasks',
            'invite': 'Invite',
            'coupon': 'Coupon',
        },
    },'langs':[
        {'title': 'English','code': 'en'},
        {'title': 'Türkçe','code': 'tr'},
    ]
}