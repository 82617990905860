<template>
    <div class="recharge-box">
        <a-form-item>
            <div class="label flex" slot="label">
              <span>{{$t('recharge.amount')}}</span>
            </div>
            <a-input size="large" ref="amount" v-model="recharge_amount">
            </a-input>
        </a-form-item>
        <div class="btn-primary mg30" @click="recharge">
            {{$t('recharge.recharge')}}
        </div>
    </div>
</template>
<script>

export default {
  name: 'Recharge',
  data(){
    return {
      recharge_amount: 100,
      recharge_channel: 0,
      submitLoading: false
    }
  },
  mounted () {
    this.$refs.amount.focus();
  },
  components:{
      
  },
  computed:{
    
  },
  methods:{
    recharge(){
      if(this.submitLoading){
        return;
      }
      if(!this.recharge_amount){
        this.$message.error(this.$t('recharge.tipEnterAmount'));
        return;
      }
      this.submitLoading = true;
      this.$http.call(this.$http.api.recharge,{
        data:{amount: this.recharge_amount,c: this.recharge_channel}
      }).then(res=>{
        console.log(res);
        this.submitLoading = false;
        let resp = res.data;
        if(resp.code=='200'){
          if(resp.data.code==0 && resp.data.message=='SUCCESS'){
            window.location.href = resp.data.data.attach;
          }

        }else{
          this.$message.error(resp.data);
        } 
      },res=>{
        console.log('something goes wrong...');
        console.log(res.response);
        this.submitLoading = false;
        if(res.response.status == '401'){
            this.$store.commit('setToken','');
            this.$router.push({name:'login'});
        }
      }).then(()=>{
          
      });
    }
  }
}
</script>
<style lang="less">
.recharge-box{
  padding: 20px 15px;
  .ant-input{
    background-color: @bg-primary;
    border:0;
    color: @gray;
  }
}

</style>
