<template>
  <div id="app">
    <div :class="isBlur">
    <Logo v-if="$store.state.showLogo" />
    <div class="main">
      <router-view :key="$route.fullPath" />
    </div>
    <!-- <Footer /> -->
    </div>
    <Nav v-if="$store.state.showNav" />
    <div v-if="$store.state.showLoading" class="loading-mask">
      <div class="mask"></div>
      <a-spin size="large" />
    </div>
    <div v-if="$store.state.showMask" class="gMask" @click="closeMask"></div>
    <div v-if="$store.state.showBtmBox" class="btm-box animate__animated animate__slideInUp">
      <div v-if="$store.state.showSetFundpassBox" class="no-fundpass-box">
        <div class="pd-y-2 text">{{$t('wallet.noFundpassText')}}</div>
        <div class="btn-primary" @click="goSetFundpass">
          {{$t('wallet.goSet')}}
        </div>
      </div>
      <Withdrawl v-if="$store.state.showWithdrawlBox" />
      <Recharge v-if="$store.state.showRechargeBox" />
      <Invite v-if="$store.state.showInviteBox" />
      <sel-lang v-if="$store.state.showLangBox"></sel-lang>
    </div>
    <div v-if="maintenance" class="maintenance">
      <div>{{$t('common.maintenance')}}</div>
    </div>
  </div>
</template>

<script>
import Logo from "./components/Logo.vue";
// import Footer from "./components/Footer.vue";
import Nav from "./components/Nav.vue";
import Withdrawl from './components/Withdrawl.vue';
import Invite from './components/Invite.vue';
import SelLang from './components/SelLang.vue';
import Recharge from './components/Recharge.vue';
import config from './config';

export default {
  name: 'App',
  components: {
    Logo,
    // Footer,
    Nav,
    Withdrawl,Invite,SelLang,
    Recharge
  },
  data(){
    return {}
  },
  computed:{
    isBlur(){
      return this.$store.state.showMask?'blur':'';
    },
  },
  created(){
    this.maintenance = config._MAINTENANCE;    
    this.$i18n.locale = this.$store.state.lang;
  },
  methods: {
    goSetFundpass(){
      this.closeMask();
      this.$router.push({name:'security'});
    },
    closeMask(){
      this.$store.commit('setBtmBox', false);
    },
  }
}
</script>

<style lang="less">
@font-face { 
    font-family: 'manroper';
    src: url('./assets/font/Manrope-Regular.ttf');
}
#app {
  font-family: Manrope,manroper,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding-bottom: 88px;
  // position: relative;
  // background: @bg-primary;
  color: @white;
  font-size: @base-font-size;
  min-height:100vh;
  // background-image:linear-gradient(60deg, #7d3370,#523297,#290d62,#00237d, #020042)
  background-image:linear-gradient(130deg, #EC5053,#DF474A,#F2A354,#eb7f0c, #a23c0c)
}
.main{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.maintenance{
  position: fixed;
  top:0;left:0;right:0;bottom: 0;
  background: @bg-second;
  color: @white;
  z-index: 9999;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
