module.exports = {
    'common': {
        'submit': 'Gönder',
        'register_btn': 'üye ol',
        'login_btn': 'giriş',
        'logout': 'çıkış',
        'more': 'DAHA FAZLA',
        'submitSuccess': 'başarılı gönderme',
        'copySuccess':'başarını kopyalama',
        'saveImgTips':'uzun dokunma veya ekran görüntüsü',
        'maintenance': 'Bakım çalışmalarında'
    },'navs':{
        'home': 'Ana sayfa',
        'user': 'Kullanıcı',
        'profit': 'kâr',
        'college': 'Üniversite'
    },'register': {
        'title': 'Üye ol',
        'otp': 'OTP',
        'label': {
            'referer': 'Hakem',
            'account': 'Hesap',
            'password': 'Şifre',
            'verifyCode': 'OTP Kodu',
        },
        'placeholders': {
            'account': 'Telefon numaran',
            'otpCode': 'OTP kodu gönderildi',
            'password': 'Şifren',
            'referer': 'referer kodun'
        },
        'tips': {
            'iptError': 'Bilgiler yanlış. Lütfen girişinizi kontrol edin',
        },
        'already_registered': 'Zaten hesabın var mı?'
    },'login': {
        'title': 'Üye ol',
        'label': {
            'account': 'Hesap',
            'password': 'Şifre',
        },
        'forget': 'Şifre unuttun mu?',
        'tips': {
            'iptError': 'Geçersiz giriş',
            'accountorpasswordwrong': 'hesap veya şifre yanlış',
            'forget': 'Geri almak için müşteri hizmetleriyle iletişime geçin',
        }
    },'wallet':{
        'amountLabel': 'Varlıklar Bakiyesi',
        'endtime': 'BİTİŞ ZAMANI',
        'noFundpassText': 'Fon şifresi belirlemediniz',
        'goSet': 'Hadi ayarla',
        'withdrawl':{
            'btn': 'Geri Çekilme',
            'bankname': 'Banka ismi',
            'bankno': 'Banka Numarası',
            'account': 'Banka hesabı',
            'amount': 'miktar',
            'depositAmount': 'miktar',
            'password': 'Fon Şifresi',
            'canuse': 'bakiye',
            'max': 'Maksimum',
            'forget': 'Unuttun mu?'
        },
        'labels':{
            'SerialNumber': 'Seri Numarası',
            'orderId': 'Sipariş numarası',
        },
        'typeText': [
            "Alışveriş yapma","bonus paylaşma","alışveriş bonus","Geri sipariş","hediye","kayıt verme"
        ]
    },'tasks':{
        'join': 'katıl',
        'andsoon': 've böylece {count} öğeleri....',
        'labels': {
            'amount':'Miktar',
            'profit': 'Kâr',
            'time': 'Tarih',
            'price': 'Fiyat'
        },
        'time': '{time}günler',
        'taskTypes':['Açık','Kapalı'],
        'progress': ['Be','İLERLEME','Bitti'],
        'checkBtn': ['GOT!','CHECK'],
    },'recharge':{
        'amount': 'miktar',
        'recharge': 'Tekrar şarj etme',
        'status':["Beklemede","başarılı",'iptal edildi'],
        'tipEnterAmount': 'Lütfen tekrar şarj etmek istediğiniz tutarı yazın'
    },'security':{
        'title': 'güvenlik ',
        'fundPass': 'fon şifresi',
        'loginPass': 'Üye şifresi',
        'oldPassword': 'Eski şifre',
        'password': 'Yeni şifre',
        'cpassword': 'Onaylayın',
        'cantbnull': 'Lütfen hem orijinal şifreyi hem de yeni şifreyi yazın',
        'diffrentPassword':'Şifre tutarlı değil',
        'needFundpass': 'Lütfen fon şifresini yazın'
    },'profile': {
        'fixedAsset': 'Sabit Varlıklar',
        'labels': {
            'available': 'Mevcut miktar',
            'freezz': 'Sözleşmenin dondurulması',
            'shoppingbonus': 'Alışveriş bonusu',
            'teamcommission': 'Takım Komisyonu',
            'todayprofit': 'Bugün kâr',
            'withdrawled': 'Çekilmiş toplam'
        },
        'titles':{
            'invite': 'davetiye',
            'accountSecurity': 'güvenlik ',
            'language': 'dil',
            'customService': 'Servis',
            'aboutUs': 'Bizimle ilgili',
            'team': 'Takımım',
            'logout': 'Çıkış'
        },
        'invite':{
            'saveUrl': 'Kopyalama',
            'saveImg': 'Depolama',
        },
    },'withdrawl':{
        'title': 'Geri Çekilme',
        'lackOfBalance': 'Yetersiz Bakiye',
        'cantbnull': 'Lütfen para çekme tutarını ve fon şifresini yazın',
        'forgetPassTips':'Kullanıcı sayfasında fon şifresini sıfırlama',
        'placeholder': 'Çekmek istediğiniz miktar',
        'amount':'miktar',
        'status':["Beklemede","geçti","reddedildi"],
    },'team':{
        'totalInvited': 'Toplam davetli',
        'searchPlaceholder': 'Araştırma',
        
    },'share':{
        'title': 'Arkadaşlarınızı davet edin',
        'text': 'Bize katılın',
    },'home':{
        'navs': {
            'recharge': 'Tekrar şarj etme',
            'withdrawl': 'Geri çekilme',
            'diamond': 'Görevler',
            'invite': 'Davet et',
            'coupon': 'Promosyon',
        },
    },'langs':[
        {'title': 'English','code': 'en'},
        {'title': 'Türkçe','code': 'tr'},
    ]
}