<template>
  <div class="invite-friend">
    <div v-if="$store.state.userinfo.inviteCode">
    <vue-qr :correctLevel="3" :autoColor="false" :text="shareUrl" :size="150" :margin="10" :logoSrc="logoUrl" logoBackgroundColor="#EC5053" :logoMargin="2"></vue-qr>
    </div>
    <div class="box mg-t-2">
      <span>{{shareUrl}}</span>
    </div>
    <div class="flex btns">
      <div class="btn btn-primary-ghost" @click="saveImg">
        {{$t('profile.invite.saveImg')}}
      </div>
      <div class="btn btn-primary" @click="copyLink">
        {{$t('profile.invite.saveUrl')}}
      </div>
    </div>
  </div>
</template>

<script>
import config from '../config';
import VueQr from 'vue-qr';
export default {
  name: "Invite",
  data() {
    return {
      shareUrl: config._BASE_URL+'register/'+this.$store.state.userinfo.inviteCode,
      logoUrl: '/statics/imgs/logo_icon.png'
    };
  },
  components:{
      VueQr
  },
  mounted() {
    console.log(this.shareUrl)
      // this.getData();
  },
  methods: {
    saveImg(){
      this.$message.info(this.$t('common.saveImgTips'));
    },
    copyLink(){
      let oInput = document.createElement('input');
      oInput.value = this.shareUrl;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success(this.$t('common.copySuccess'));
      oInput.remove();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.invite-friend{
  .box{
    background: @bg-primary;
    padding: 15px;
    word-break: break-all;
  }
}
.btns{
  .btn{
    margin: 15px;
    flex: 1;
  }
}
</style>
