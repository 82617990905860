<template>
    <div class="nav">
      <a-row type="flex" justify="space-between" align="top">
        <a-col @click="goPage('home')" class="nav-item" :class="$route.name=='home'?'on':''" :span="4">
          <a-icon class="icon" type="home" />
          <span class="text">{{$t('navs.home')}}</span>
        </a-col>
        <a-col @click="goPage('college')" class="nav-item" :class="$route.name=='college'?'on':''" :span="4">
          <a-icon class="icon" type="heart" />
          <span class="text">{{$t('navs.college')}}</span>
        </a-col>
        <a-col @click="goPage('tasks')" class="nav-item" :span="4">
            <a-icon class="icon center-light" type="sketch" />
        </a-col>
        <a-col @click="goPage('wallet')" class="nav-item" :class="$route.name=='wallet'?'on':''" :span="4">
          <a-icon class="icon" type="rise" />
          <span class="text">{{$t('navs.profit')}}</span>
        </a-col>
        <a-col @click="goPage('user')" class="nav-item" :class="$route.name=='user'?'on':''" :span="4">
          <a-icon class="icon" type="user" />
          <span class="text">{{$t('navs.user')}}</span>
        </a-col>
      </a-row>
    </div>
</template>

<script>
    export default {
        name: 'Nav',
        data(){
            return {}
        },
        props: {
            // address: String
        },
        computed: {
            // url(){
            //     return 'data:image/png;base64,' + new Identicon(Md5(this.address), 420).toString();
            // }
        },
        methods:{
          goPage(routeName){
            if(routeName != this.$route.name){
              this.$router.push({name: routeName});
            }
          }
        }
    }
</script>

<style lang="less" scoped>
.nav{
  background: @white;
  position: fixed;
  bottom:0;
  left:0;
  right:0;
  // margin: 0 auto;
  padding: 10px 0;
  color: @bg-primary;
  box-shadow: 0 -2px 8px 0 @red;
  
  .nav-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .icon{
      font-size: 1.2rem;
    }
    .text{
      padding: 3px 0;
    }
    .center-light{
      font-size: 2rem;
      background: @red;
      color: #fff;
      padding: 15px;
      position: absolute;
      top: -30px;
      border-radius: 50%;
      font-size: 2rem;
      box-shadow: 0 0 10px 0 @red;
      
    }
  }.on{
    color: @red;
    font-weight: bold;
  }
}

</style>