<template>
<div>
    <div class="logo">
      <img src="@/assets/logo.png" alt="">
    </div>
    
</div>
    
</template>

<script>
export default {
  name: 'Logo',
  data(){
      return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo{
  padding: 1rem 0;
  display:flex;
  justify-content: center;
  align-items: center;
}
.logo img{
  height: 2.4rem;
}
</style>
