import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

// 屏蔽双击路由链接报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

// const Hashsoul = () => import ('../views/PlayView');
// const HowtoPlay = () => import ('../views/HowtoPlay');
// const Records = () => import ('../views/Records');
// const Flow = () => import ('../views/Flows');
const Register = () => import ('../views/Register');
const Login = () => import ('../views/Login');
const Profile = () => import ('../views/Profile');
const Team = () => import ('../views/Team');
const Wallet = () => import ('../views/Wallet');
// const Profit = () => import ('../views/Profit');
// const Deposit = () => import ('../views/Deposit');
// const DefiLock = () => import ('../views/DefiLock');
// const NodeLevel = () => import ('../views/NodeLevel');
// const DefiLockProfits = () => import ('../views/DefiLockProfits');
const Withdrawl = () => import ('../views/Withdrawl');
// const Defi = () => import ('../views/Defi');
// const Insurance = () => import ('../views/Insurance');
// const Insurances = () => import ('../views/Insurances');
// const InsuranceProfits = () => import ('../views/InsuranceProfits');
// const InsuranceDividends = () => import ('../views/InsuranceDividends');
// const Exchange = () => import ('../views/Exchange');
// const ExcTranslation = () => import ('../views/ExcTranslation');
// const ExcOrders = () => import ('../views/ExcOrders');
const Tasks = () => import ('../views/Tasks');
const Task = () => import ('../views/Task');
const College = () => import ('../views/College');
const Share = () => import ('../views/Share');
const Article = () => import ('../views/Article');
const Bag = () => import ('../views/Taskbag');
const Security = () => import ('../views/Security');
// const Manage = () => import ('../views/Manage');
const Home = () => import ('../views/Home');
// const Game = () => import ('../views/Game');
// const Mall = () => import ('../views/Mall');
// const MallUser = () => import ('../views/MallUser');
// const MallSupplier = () => import ('../views/MallSupplier');
// const MallGoods = () => import ('../views/MallGoods');
// const MallSwap = () => import ('../views/Swap');

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta:{
        // 页面标题title
        title: 'CONDE'
    }
}, {
    path: '/user',
    name: 'user',
    component: Profile
}, {
    path: '/register/:i?',
    name: 'register',
    component: Register,
    meta:{
        // 页面标题title
        title: 'Register'
    }
}, {
    path: '/login',
    name: 'login',
    component: Login,
    meta:{
        // 页面标题title
        title: 'Login'
    }
}, {
    path: '/share',
    name: 'share',
    component: Share,
    meta:{
        // 页面标题title
        title: 'Share'
    }
}, {
    path: '/article/:id?',
    name: 'article',
    component: Article,
    meta:{
        // 页面标题title
        title: 'Article'
    }
}, {
    path: '/security',
    name: 'security',
    component: Security
}, {
    path: '/bag/:id',
    name: 'bag',
    component: Bag
}, {
    path: '/wallet',
    name: 'wallet',
    component: Wallet,
    meta:{
        // 页面标题title
        title: 'Wallet'
    }
}, 
// {
//     path: '/profits/:mode?',
//     name: 'profits',
//     component: Profit,
//     meta:{
//         // 页面标题title
//         title: 'Profits'
//     }
// }, {
    // path: '/manage',
    // name: 'manage',
    // component: Manage
// }, 
{
    path: '/withdrawls',
    name: 'withdrawls',
    component: Withdrawl,
    meta:{
        // 页面标题title
        title: 'Withdrawl'
    }
}, 
// {
//     path: '/defi',
//     name: 'defi',
//     component: Defi
// }, {
//     path: '/insurance',
//     name: 'insurance',
//     component: Insurance
// }, {
//     path: '/exchange',
//     name: 'exchange',
//     component: Exchange
// }, {
//     path: '/excTranslation',
//     name: 'excTranslation',
//     component: ExcTranslation
// }, {
//     path: '/excOrders',
//     name: 'excOrders',
//     component: ExcOrders
// }, {
//     path: '/deposit',
//     name: 'deposit',
//     component: Deposit
// }, {
//     path: '/insurances',
//     name: 'insurances',
//     component: Insurances
// }, {
//     path: '/insuranceProfits',
//     name: 'insuranceProfits',
//     component: InsuranceProfits
// }, {
//     path: '/insuranceDividends',
//     name: 'insuranceDividends',
//     component: InsuranceDividends
// }, {
//     path: '/defiLock',
//     name: 'defiLock',
//     component: DefiLock
// }, {
//     path: '/depositProfits',
//     name: 'depositProfits',
//     component: NodeLevel
// }, {
//     path: '/defiLockProfits',
//     name: 'defiLockProfits',
//     component: DefiLockProfits
// }, 
{
    path: '/tasks',
    name: 'tasks',
    component: Tasks
}, {
    path: '/task/:id',
    name: 'task',
    component: Task
}, {
    path: '/college',
    name: 'college',
    component: College
}, {
    path: '/team',
    name: 'team',
    component: Team
}, 
// {
//     path: '/records/:address?',
//     name: 'records',
//     component: Records
// }, {
//     path: '/flow',
//     name: 'flow',
//     component: Flow
// }, {
//     path: '/game',
//     name: 'game',
//     component: Game
// }, {
//     path: '/mall',
//     name: 'mall',
//     component: Mall
// }, {
//     path: '/mallgoods/:id?',
//     name: 'mallgoods',
//     component: MallGoods,
// }, {
//     path: '/mallUser',
//     name: 'mallUser',
//     component: MallUser
// }, {
//     path: '/mallSupplier',
//     name: 'mallSupplier',
//     component: MallSupplier
// }, {
//     path: '/mallSwap',
//     name: 'mallSwap',
//     component: MallSwap
// }
];

const router = new VueRouter({
    mode: "hash",
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    // if (to.meta.title) {
    //   document.title = to.meta.title
    // }
    let token = store.state.token;
    if (!token && to.name != 'login' && to.name != 'register') {
        return next({ name: 'login' });
    }
    if (token && (to.name == 'login' || to.name=='register')){
        return next({ name: 'home'});
    }
    // if(['login','register'].indexOf(to.name)>=0){
    //     store.commit("setShowLogo", true);
    //     store.commit("setShowNav", false);
    // }
    // if(['login'].indexOf(from.name)>=0){
    //     // store.commit("setShowLogo", true);
    //     store.commit("setShowNav", true);
    // }
    let noLogoRoutes = ['security','article','team','user'];
    if(noLogoRoutes.indexOf(to.name)>=0){
        store.commit("setShowLogo", false);
        // store.commit("setShowNav", false);
    }
    if(noLogoRoutes.indexOf(from.name)>=0){
        if(from.name!=to.name && noLogoRoutes.indexOf(to.name)==-1){
            store.commit("setShowLogo", true);
            store.commit("setShowNav", true);
        }
    }
    next();
  })
export default router;