export default {
    login: {
        method: 'post',
        url: '/login',
        hasToken: false
    },
    register: {
        method: 'post',
        url: '/register',
        hasToken: false
    },
    signup: {
        method: 'post',
        url: '/signup',
        hasToken: false
    },
    sendmsg: {
        method: 'post',
        url: '/sms',
        hasToken: false
    },
    logout:{
        method: 'delete',
        url: '/logout',
        hasToken: true
    },
    userinfo: {
        method: 'post',
        url: '/userinfo',
        hasToken: true
    },
    bags: {
        method: 'post',
        url: '/bags',
        hasToken: true
    },
    gains: {
        method: 'post',
        url: '/gains',
        hasToken: true
    },
    bag: {
        method: 'post',
        url: '/bag',
        hasToken: true
    },
    buybag: {
        method: 'post',
        url: '/buybag',
        hasToken: true
    },
    goodsList:{
        method: 'post',
        url: '/goodsList',
        hasToken: true
    },
    teams: {
        method: 'post',
        url: '/teams',
        hasToken: true
    },
    // releaseLogs: {
    //     method: 'post',
    //     url: '/releaseLogs',
    //     hasToken: true
    // },
    ulogs: {
        method: 'post',
        url: '/ulogs',
        hasToken: true
    },
    flows: {
        method: 'post',
        url: '/flows',
        hasToken: true
    },
    tasks: {
        method: 'post',
        url: '/tasks',
        hasToken: true
    },
    task: {
        method: 'post',
        url: '/task',
        hasToken: true
    },
    ctask: {
        method: 'post',
        url: '/ctask',
        hasToken: true
    },
    profits: {
        method: 'post',
        url: '/profits',
        hasToken: true
    },
    // deposits: {
    //     method: 'post',
    //     url: '/deposits',
    //     hasToken: true
    // },
    // defiLock: {
    //     method: 'post',
    //     url: '/defiLock',
    //     hasToken: true
    // },
    // defiLockProfits: {
    //     method: 'post',
    //     url: '/defiLockProfits',
    //     hasToken: true
    // },
    // nodesinfo: {
    //     method: 'post',
    //     url: '/depositNodes',
    //     hasToken: true
    // },
    // depositProfits: {
    //     method: 'post',
    //     url: '/depositProfits',
    //     hasToken: true
    // },
    // depositEnd: {
    //     method: 'post',
    //     url: '/depositEnd',
    //     hasToken: true
    // },
    // depositRenew: {
    //     method: 'post',
    //     url: '/depositRenew',
    //     hasToken: true
    // },
    recharge: {
        method: 'post',
        url: '/recharge',
        hasToken: true
    },
    recharges: {
        method: 'post',
        url: '/recharges',
        hasToken: true
    },
    records: {
        method: 'post',
        url: '/records',
        hasToken: true
    },
    wallets: {
        method: 'post',
        url: '/wallets',
        hasToken: true
    },
    withdrawl: {
        method: 'post',
        url: '/withdrawl',
        hasToken: true
    },
    withdrawls: {
        method: 'post',
        url: '/withdrawls',
        hasToken: true
    },
    policy: {
        method: 'post',
        url: '/policy',
        hasToken: true
    },
    changePass: {
        method: 'post',
        url: '/changePassword',
        hasToken: true
    },
    fundPass: {
        method: 'post',
        url: '/fundPassword',
        hasToken: true
    },
    colleges: {
        method: 'post',
        url: '/colleges',
        hasToken: true
    },
    // insuranceProfits: {
    //     method: 'post',
    //     url: '/insuranceProfits',
    //     hasToken: true
    // },
    // insuranceDividends: {
    //     method: 'post',
    //     url: '/insuranceDividends',
    //     hasToken: true
    // },
    // insurances: {
    //     method: 'post',
    //     url: '/insurances',
    //     hasToken: true
    // },
    // exchangeUser:{
    //     method: 'post',
    //     url: '/exchangeUser',
    //     hasToken: true
    // },
    // exchangeMakeOrder:{
    //     method: 'post',
    //     url: '/exchangeMakeOrder',
    //     hasToken: true
    // },
    // exchangeMyOrders:{
    //     method: 'post',
    //     url: '/exchangeMyOrders',
    //     hasToken: true
    // },
    // exchangeOrders:{
    //     method: 'post',
    //     url: '/exchangeOrders',
    //     hasToken: true
    // },
    // exchangeCancelOrder:{
    //     method: 'post',
    //     url: '/exchangeCancelOrder',
    //     hasToken: true
    // },
    // exchangeTransOut:{
    //     method: 'post',
    //     url: '/exchangeTransOut',
    //     hasToken: true
    // },
    // exchangeTranslations:{
    //     method: 'post',
    //     url: '/exchangeTranslations',
    //     hasToken: true
    // },
    // mallgoods:{
    //     method: 'post',
    //     url: '/mallgoods',
    //     hasToken: true
    // },
    // mallgoodsList:{
    //     method: 'post',
    //     url: '/mallgoodsList',
    //     hasToken: true
    // },
    // malluser:{
    //     method: 'post',
    //     url: '/malluser',
    //     hasToken: true
    // }
}