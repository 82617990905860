     export default{
    _TOKEN_KEY: 'token',
    _LANG_KEY: 'lang',
    _DEFAULT_LANG: 'en',
    _MAINTENANCE: false,
    _API_URL: process.env.NODE_ENV === 'production'
            ? 'https://mvvc168.goconde.com/api/'
            : 'http://laravel.test/api/',
    _BASE_URL: process.env.NODE_ENV === 'production'
            ? 'https://h5.goconde.com/#/'
            : 'http://localhost:8080/#/',
}
